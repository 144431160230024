import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';  // import useLocation to determine the current route
import { IoMdHome } from 'react-icons/io';
import { LuActivitySquare } from 'react-icons/lu';
import { FaWallet } from 'react-icons/fa';
import { MdAccountCircle } from 'react-icons/md';
import { IoDiamond } from "react-icons/io5";

const Footer = () => {
  const location = useLocation(); // get the current route location
  const [activeLink, setActiveLink] = useState(location.pathname,1); // Set default active link based on current route

  const handleLinkClick = (linkPath) => {
    setActiveLink(linkPath); // Set the active link to the clicked path
  };

  return (
    <footer className="footer">
      <div className="d-flex justify-content-between gap_custum">

        {/* Home Link */}
        <div 
          className={`footerlink text-center ${activeLink === "/home" ? "active" : ""}`} 
          onClick={() => handleLinkClick("/home")}
        >
          <Link to="/home" className="footerlink">
            <div className="footericon d-flex justify-content-center">
              <IoMdHome />
            </div>
            <span>Home</span>
          </Link>
        </div>

        {/* Activity Link */}
        <div 
          className={`footerlink text-center ${activeLink === "/activity" ? "active" : ""}`} 
          onClick={() => handleLinkClick("/activity")}
        >
          <Link to="/activity" className="footerlink">
            <div className="footericon d-flex justify-content-center">
              <LuActivitySquare />
            </div>
            <span>Activity</span>
          </Link>
        </div>

        {/* Promotion Link */}
        <div 
          className={`footerlink text-center ${activeLink === "/promotion" ? "active" : ""}`} 
          onClick={() => handleLinkClick("/promotion")}
        >
          <Link to="/promotion" className="footerlink">
            <div className="footericon promotion d-flex justify-content-center">
              <IoDiamond />
            </div>
            <span>Promotion</span>
          </Link>
        </div>

        {/* Wallet Link */}
        <div 
          className={`footerlink text-center ${activeLink === "/wallet" ? "active" : ""}`} 
          onClick={() => handleLinkClick("/wallet")}
        >
          <Link to="/wallet" className="footerlink">
            <div className="footericon d-flex justify-content-center">
              <FaWallet />
            </div>
            <span>Wallet</span>
          </Link>
        </div>

        {/* Account Link */}
        <div 
          className={`footerlink text-center ${activeLink === "/account" ? "active" : ""}`} 
          onClick={() => handleLinkClick("/account")}
        >
          <Link to="/account" className="footerlink">
            <div className="footericon d-flex justify-content-center">
              <MdAccountCircle />
            </div>
            <span>Account</span>
          </Link>
        </div>

      </div>
    </footer>
  );
};

export default Footer;

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Card, Container } from 'react-bootstrap';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight, MdCurrencyRupee } from "react-icons/md";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import nodata from '../assets/images/nodata.png';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import axios from 'axios';
import { format } from 'date-fns';

export default function Withdrawhistory() {
    const [displayedValue, setDisplayedValue] = useState('All');
    const userId = localStorage.getItem('user_id');
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [withdrawHistory, setWithdrawHistory] = useState([]);
    const [loading, setLoading] = useState(false);

    const togglePopup = () => {
        setPopupOpen(!isPopupOpen);
    };

    useEffect(() => {
        fetchWithdrawHistory();
    }, []);

    const fetchWithdrawHistory = async () => {
        try {
            setLoading(true);
            const url = `${process.env.REACT_APP_API_URL}getWithdrawHistory?user_id=${userId}`;
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const response = await axios(url, config);
            setWithdrawHistory(response.data.data || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching withdraw history:', error.response ? error.response.data : error.message);
            setLoading(false);
        }
    };

    function CustomModelPopup({ isOpen, onClose }) {
        const [selectedValue, setSelectedValue] = useState('');
        const listRef = useRef(null);

        const handleSelectChange = (event) => {
            setSelectedValue(event.target.value);
        };

        const handleButtonClick = () => {
            setDisplayedValue(selectedValue);
            onClose(); // Close the popup after selection
        };

        const handleMouseWheel = (event) => {
            const delta = Math.sign(event.deltaY);
            listRef.current.scrollTop += delta * 30; // Adjust scrolling speed as needed
        };

        const handleListItemClick = (value) => {
            setSelectedValue(value);
        };

        return (
            <div className={`custom-model-popup ${isOpen ? 'open' : ''}`}>
                <div className="popup-content">
                    <div className='d-flex justify-content-between align-items-center'>
                        <button onClick={onClose}>Close</button>
                        <button onClick={handleButtonClick} className="text-blue">Confirm</button>
                    </div>
                    <ul
                        ref={listRef}
                        className="withdrawal_status"
                        onWheel={handleMouseWheel}
                    >
                        <li
                            onClick={() => handleListItemClick('All')}
                            className={selectedValue === 'All' ? 'highlighted' : ''}
                        >
                            All
                        </li>
                        <li
                            onClick={() => handleListItemClick('Processing')}
                            className={selectedValue === 'Processing' ? 'highlighted' : ''}
                        >
                            Processing
                        </li>
                        <li
                            onClick={() => handleListItemClick('Completed')}
                            className={selectedValue === 'Completed' ? 'highlighted' : ''}
                        >
                            Completed
                        </li>
                        <li
                            onClick={() => handleListItemClick('Rejected')}
                            className={selectedValue === 'Rejected' ? 'highlighted' : ''}
                        >
                            Rejected
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    return (
        <section className='Withdrawhistory'>
            <div className='topheader p-2 bg-color-all d-flex justify-content-between align-items-center'>
                <Link to="/Withdraw" className='text-white text-2xl'><MdOutlineKeyboardArrowLeft /></Link>
                <h4 className="mb-0 text-white">Withdrawal history</h4>
                <div className='w-5'></div> {/* Empty div to balance the header */}
            </div>

            <Container>
                <div className="history_type">
                    <Tabs>
                        <TabList>
                            <Tab>All</Tab>
                            <Tab>Bank Card</Tab>
                        </TabList>

                        <TabPanel>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div onClick={togglePopup} className="All_type d-flex justify-content-between align-items-center">
                                    <p>{displayedValue}</p>
                                    <MdOutlineKeyboardArrowRight />
                                </div>
                                <div className="All_type">
                                    <DateRangePicker>
                                        <input type="text" className="form-control datepicker" />
                                    </DateRangePicker>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div onClick={togglePopup} className="All_type d-flex justify-content-between align-items-center">
                                    <p>{displayedValue}</p>
                                    <MdOutlineKeyboardArrowRight />
                                </div>
                                <div className="All_type">
                                    <DateRangePicker>
                                        <input type="text" className="form-control datepicker" />
                                    </DateRangePicker>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div onClick={togglePopup} className="All_type d-flex justify-content-between align-items-center">
                                    <p>{displayedValue}</p>
                                    <MdOutlineKeyboardArrowRight />
                                </div>
                                <div className="All_type">
                                    <DateRangePicker>
                                        <input type="text" className="form-control datepicker" />
                                    </DateRangePicker>
                                </div>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>

                {loading ? (
                    <p>Loading...</p>
                ) : withdrawHistory.length === 0 ? (
                    <div className="w-50 h-50 mx-auto pt-4">
                        <img src={nodata} className='img-fluid' alt="No data" />
                    </div>
                ) : (
                    withdrawHistory.map((withdrawal, index) => (
                        <Card className="due_fees mt-3" key={index}>
                            <Card.Body>
                                <div className="fee_card">
                                    {/* <div className="d-flex justify-content-between mb-2">
                                        <div className={`deposit_status ${withdrawal.status === 'Failed' ? 'bg-danger' : 'bg-success'}`}>{withdrawal.type}</div>
                                        <h6 className={withdrawal.status === 'Failed' ? 'text-danger' : 'text-success'}>{withdrawal.status}</h6>
                                    </div> */}
                                    <div className="d-flex justify-content-between mb-2">
                            <div className='deposit_status bg-success'>Withdraw</div>
                         
                         <h6 className='text-success'>Success</h6>

                         
                         </div>
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6><small>Amount</small></h6>
                                            <h6 className="d-flex gap-1"><MdCurrencyRupee /><small>{withdrawal.amount}</small></h6>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6><small>Bank Name</small></h6>
                                            <h6><small>{withdrawal.bank_name}</small></h6>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6><small>Time</small></h6>
                                            <h6><small>{format(new Date(withdrawal.created_at), 'yyyy-MM-dd HH:mm:ss')}</small></h6>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6><small>Account Number</small></h6>
                                            <h6 className="gap-1 d-flex"><small>{withdrawal.account_number}</small></h6>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    ))
                )}
            </Container>

            <CustomModelPopup isOpen={isPopupOpen} onClose={togglePopup} />
        </section>
    );
}

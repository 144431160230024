import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { IoMdWallet } from "react-icons/io";
import { MdCurrencyRupee } from "react-icons/md";
import { MdRefresh } from "react-icons/md";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import { FaCreditCard, FaPlus, FaChevronRight } from "react-icons/fa";
import { AiFillBank } from "react-icons/ai";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import axios from "axios";
import { useCredit } from "./AllLotteryGames/CreditContext";
import chip from "../assets/images/chip.png";
import Swal from 'sweetalert2';

const Withdraw = () => {
  const user_id = localStorage.getItem("user_id");
  const account_number = localStorage.getItem("account_number");
  const ifsc_code = localStorage.getItem("ifsc_code");
  const name = localStorage.getItem("name");
  const mobile = localStorage.getItem("mobile");
  const email = localStorage.getItem("email");
  const bank_name = localStorage.getItem("bank_name");

  const { credit, updateCredit } = useCredit();
  const [withdrawAmount, setWithdrawAmount] = useState(0.0);

  // const [bankname setBankName] = useState("");
  // const [accnumber setAccNumber] = useState("");

  const [formData, setFormData] = useState({
    account_number: account_number,
    ifsc_code: ifsc_code,
    mobile: mobile,
    email: email,
    name: name,
    bank_name: bank_name,
    amount: 0,
  });
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAmount();
  }, []);

  const fetchAmount = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}profile-list`;

      const config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: { user_id },
      };

      const response = await axios(config);
      const responseData = response.data.data;
      setUserData(responseData);
      // setAccNumber(responseData.account_number);
      // setBankName(responseData.bank_name);
      updateCredit(responseData.credit);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  const withdrawAmt = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}withdraw`;
  
      const config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: { ...formData, user_id, amount: withdrawAmount },
      };
  
      const response = await axios(config);
      const responseData = response.data;
  
      if (responseData.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: responseData.message || 'Withdrawal successful!',
        });
  
        // Fetch the updated user data to get the latest credit amount
        await fetchAmount();
        
        // Reset withdraw amount
        setWithdrawAmount(0);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: responseData.message || 'please select account details',
        });
      }
      
      setLoading(false);
    } catch (error) {
      console.error("Error withdrawing amount:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to withdraw. Please try again.',
      });
      setLoading(false);
    }
  };
  

  const handleAllClick = () => {
    setWithdrawAmount(credit);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <section className="withdraw">
      <div className="topheader p-2 bg-color-all d-flex justify-content-between">
        <Link to="/Wingo" className="text-white text-2xl">
          <MdOutlineKeyboardArrowLeft />
        </Link>
        <h4 className="mb-0 text-white">Withdraw</h4>
        <Link to="/Withdrawhistory" className='w-5 text-white'>
        <p className='mb-0 float-end'>History</p>
        </Link>
        {/* <Link to="/" className="w-5"></Link> */}
      </div>
      <Container>
        <div className="card_design_custum p-2">
          <div className="d-flex align-items-center">
            <IoMdWallet />
            <p className="mb-0 text-sm">Available Balance</p>
          </div>
          <h3 className="d-flex align-items-center mt-2">
            <MdCurrencyRupee />
            <span className="mx-1"> {(Number(credit) || 0).toFixed(2)} </span>
            <MdRefresh />
          </h3>
          <div className="d-flex justify-content-between align-items-center">
            <div className="chip">
              <img src={chip} alt="chip" />
            </div>
            <p className="mb-0 text-sm">*** ****</p>
          </div>
        </div>
        <div className="withdrawaltab">
          <Tabs>
            <TabList className="border-0 pl-0 d-flex ">
              <Tab>
                <div>
                  <div className="d-flex justify-content-center">
                    <FaCreditCard className="text-white" />
                  </div>
                  <h4 className="mb-0 pb-0 text-sm text-white">Bank Card</h4>
                </div>
              </Tab>
            </TabList>
            <TabPanel>
              <div className="card_custum rounded-smmb-2 p-3 mb-2">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="bankname text-center d-flex flex-col justify-content-center">
                      <div className="d-flex justify-content-center">
                        <AiFillBank />
                      </div>
                      {bank_name}
                    </div>
                    |<div className="accountnumber">{account_number}</div>
                    <Link to="/Bankaccount">
                      <FaChevronRight />
                    </Link>
                  </div>
                </div>
              </div>
              <Link to="/AddBankCard" className="no-underline">
                <div className="card_custum rounded-sm	 ">
                  {/* <div className="d-flex justify-content-center p-4  flex-col gap-2">
                    <div className="d-flex justify-content-center">
                      <FaPlus className="text-sm  plusicon" />
                    </div>
                    <div className="text-sm"> Add a bank account number</div>
                  </div> */}
                </div>
              </Link>
            </TabPanel>
          </Tabs>
        </div>
        <p className="need-info">
          Need to add beneficiary information to be able to withdraw money
        </p>
        <div className="card">
          <div className="card-body">
            <div className="form-group position-relative">
              <input
                type="number"
                placeholder="Please enter the amount"
                name="amount"
                value={withdrawAmount}
                onChange={(e) =>
                  setWithdrawAmount(parseFloat(e.target.value))
                }
                className="form-control withdrawinput"
              />
              <MdOutlineCurrencyRupee className="iconnew" />
            </div>
            <div className="d-flex justify-content-between">
              <span className="withdrawamount d-flex align-items-center mt-2">
                Withdraw Balance:: <MdOutlineCurrencyRupee />{" "}
                <strong>{credit}</strong>
              </span>
              <div className="all" onClick={handleAllClick}>
                All
              </div>
            </div>
            <span className="withdrawamount d-flex align-items-center mt-2">
              Withdraw Amount Received:: <MdOutlineCurrencyRupee />{" "}
              <strong>{withdrawAmount.toFixed(2)}</strong>
            </span>
            <button
              onClick={withdrawAmt}
              className="btn withdraw-amt text-center justify-content-center opacity.5 d-flex border-raidus-25"
              disabled={loading}
            >
              {loading ? "Withdrawing..." : "Withdraw"}
            </button>
          </div>
        </div>
        <div className="border-2 p-2 mt-2 ">
          <div className=" list_withdraw	rounded-sm	text-start ">
            <p className="mb-1   text-base text-sm text-blue-900	">
              Need to bet <span className="text-red-950	">₹0.00</span> to be able
              to withdraw
            </p>
            <p className="mb-1   text-base text-sm text-blue-900	">
              Withdraw time <span className="text-red-950	">00:00-23:59</span>
            </p>
            <p className="mb-1   text-base text-sm text-blue-900	">
              Inday Remaining Withdrawal Times
              <span className="text-red-950	">3</span>
            </p>
            <p className="mb-1   text-base text-sm text-blue-900	">
              Withdrawal amount range{" "}
              <span className="text-red-950	">₹1,000.00-₹1,000,000.00</span>
            </p>
            <p className="mb-1   text-base text-sm text-blue-900	">
              After withdraw, you need to confirm the blockchain main network 3
              times before it arrives at your account.
            </p>
            <p className="mb-1   text-base text-sm text-blue-900	">
              Please confirm that the operating environment is safe to avoid
              information being tampered with or leaked.
            </p>
            <p className="mb-1   text-base text-sm text-blue-900	">
              Please confirm your beneficial account information before
              withdrawing. If your information is incorrect, our company will
              not be liable for the amount of loss
            </p>
            <p className="mb-1   text-base text-sm text-blue-900	">
              If your beneficial information is incorrect, please contact
              customer service
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Withdraw;

import React, { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import { Container, Card, Spinner } from "react-bootstrap";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import { FaRegCopy } from "react-icons/fa";

export default function WingoChart({ results, setResults }) {
  const [showaccordian, setShowaccordian] = useState(null);
  const [userListData, setUserListData] = useState([]);
  const user_id = localStorage.getItem("user_id");
  const isLoading = useRef(false);
  const [loading, setLoading] = useState(false);
  const category_slug = localStorage.getItem("category_slug");
  const subcategory_slug = localStorage.getItem("sub_category_slug");
  const [totalPages, setTotalPages] = useState("");
  const intervalM = localStorage.getItem("selectedInterval");
  // alert(totalPages);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const fetchResults = async () => {
    if (!intervalM) {
      console.error("intervalM is not set");
      return;
    }
  
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}one-min-result`;
  
      const config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          gametype: intervalM, 
          page: currentPage,
          perpage: itemsPerPage,
        },
      };
  
      const response = await axios(config);
      const responseData = response.data.data;
      setTotalPages(response.data.totalPages);
  
      if (responseData) {
        setResults(responseData);
      } else {
        console.warn("No data returned from API");
        setResults([]); // Reset results to an empty array if no data is returned
      }
    } catch (error) {
      console.error("Error fetching results:", error);
    } finally {
      isLoading.current = false;
      setLoading(false);
    }
  };
  

  const fetchcolorlist = async () => {
    try {
      isLoading.current = true;
      const url = `${process.env.REACT_APP_API_URL}color-bet-list`;

      setLoading(true);
      const config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: { user_id, category_slug: category_slug, subcategory_slug: subcategory_slug },
      };

      const response = await axios(config);
      let responseData1 = response.data.data;

      if (responseData1 && responseData1.length > 0) {
        const resultDataShow = responseData1[0].result;
        // alert(resultDataShow);
      }

      // Sort responseData1 based on period_id in ascending order
      // responseData1.sort((a, b) => b.period_id - a.period_id);

      setUserListData(responseData1);



      isLoading.current = false;
      setLoading(false);
    } catch (error) {
      isLoading.current = false;
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (category_slug && subcategory_slug && !isLoading.current) {
      fetchcolorlist();
    }
    if (intervalM) {
      setResults([]); // Clear previous results to avoid showing stale data
      fetchResults();
    }
  }, [category_slug, subcategory_slug, currentPage, intervalM]);
  

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // if (!userListData || userListData.length === 0) {
  //   return <p>No data available</p>;
  // }

  const showing = (clickedItem) => {
    setShowaccordian(clickedItem === showaccordian ? null : clickedItem);
  };

  return (
    <section className="wingochart">
      <Container>
        <div className="wingo padding_all">
          <div className="history mt-2">
            <Tabs>
              <TabList>
                <Tab><button className="">Game History</button></Tab>
                <Tab><button className="">Chart</button></Tab>
                <Tab><button onClick={fetchcolorlist} className="">My History</button></Tab>
              </TabList>

              <TabPanel>
                {loading ? (
                  <Spinner animation="border" />
                ) : (
                  <>
                    <table className="table-auto table table-striped history_table">
                      <thead>
                        <tr>
                          <th className="">Period</th>
                          <th className="">Number</th>
                          <th className="">Big Small</th>
                          <th className="">Color</th>
                        </tr>
                      </thead>
                      <tbody>
                        {results && results.map((item) => (
                          <tr key={item.periodid}>
                            <td>{item.periodid || 'N/A'}</td>
                            <td><span className="number">{typeof item.number === 'number' ? item.number : 'N/A'}</span></td>
                            <td>small</td>
                            <td>
                              <div className="d-flex align-items-center justify-content-evenly">
                                {item.color && typeof item.color === 'string' ? (
                                  item.color.includes(" ") ? (
                                    item.color.split(" ").map((color, index) => (
                                      <span key={index} className="w-2 h-2 rounded-full" style={{ backgroundColor: color }}></span>
                                    ))
                                  ) : (
                                    <span className="w-2 h-2 rounded-full" style={{ backgroundColor: item.color }}></span>
                                  )
                                ) : (
                                  <span className="w-2 h-2 rounded-full d-inline-block" style={{ backgroundColor: item.color || 'NA' }}></span>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/* Pagination controls */}
                    <div className="pagination-controls bg-white mt-4">
                      <nav>
                        <ul className="pagination">
                          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button onClick={() => paginate(currentPage - 1)} className="page-link" disabled={currentPage === 1}>
                              <RiArrowLeftSLine />
                            </button>
                          </li>
                          <li className="page-item">
                            <span className="page-info">
                              {currentPage} of {totalPages}
                            </span>
                          </li>
                          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button onClick={() => paginate(currentPage + 1)} className="page-link" disabled={currentPage === totalPages}>
                              <RiArrowRightSLine />
                            </button>
                          </li>
                        </ul>
                      </nav>
                    </div>

                  </>
                )}
              </TabPanel>

              <TabPanel>
                <table className="table-auto table table-striped history_table">
                  <thead>
                    <tr>
                      <th className="">Period</th>
                      <th className="">Number</th>
                      <th className="">Big Small</th>
                      <th className="">Color</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>7456745648756</td>
                      <td><span className="number">7568576</span></td>
                      <td>big</td>
                      <td>
                        <div className="d-flex align-items-center justify-content-evenly">
                          <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                          <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </TabPanel>

              <TabPanel>
                <div className="myhistory">
                  <Card>
                  <Card.Body>
{/* Paginate userListData */}
{userListData.length === 0 ? (
  <p>Loading...</p> // Display loading message or default data when fetching
) : (
  userListData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((item, index) => (
    <React.Fragment key={index}>
      <div
        className="d-flex justify-content-between"
        onClick={() => setShowaccordian(showaccordian === item ? null : item)}
      >
        <div className="d-flex gap-2">
          <div
            className="icon_profile"
            style={{
              background:
                item.number === 0
                  ? 'linear-gradient(to bottom right, violet, #fd565c)'
                  : item.number === 5
                  ? 'linear-gradient(to bottom right, #40ad72, violet)'
                  : item.color,
            }}
          >
            {item.big_small && !item.number && item.big_small}
            {item.number && `${item.number}`}
            {!item.big_small && !item.number && item.color && `${item.color}`}
          </div>

          <div className="text-start">
            <h3 className="mb-0 pb-0 text-base">{item.period_id}</h3>
            <p className="mb-0 pb-0 text-sm text-muted">{item.datetime}</p>
          </div>
        </div>
        <div className="status_wingo">
          <p
            className="status"
            style={{
              borderColor:
                item.result === 'win'
                  ? 'green'
                  : item.result === 'loss'
                  ? 'red'
                  : item.result === 'pending'
                  ? 'orange'
                  : 'inherit',
              color:
                item.result === 'win'
                  ? 'green'
                  : item.result === 'loss'
                  ? 'red'
                  : item.result === 'pending'
                  ? 'orange'
                  : 'inherit',
            }}
          >
            {item.result === 'win'
              ? 'Success'
              : item.result === 'loss'
              ? 'Failed'
              : item.result === 'pending'
              ? 'Waiting'
              : ''}
          </p>
          <p
            style={{
              color:
                item.result === 'win'
                  ? 'green'
                  : item.result === 'loss'
                  ? 'red'
                  : 'inherit',
            }}
          >
            {item.result === 'win' ? '+' : item.result === 'loss' ? '-' : ''}
            <span>₹</span>
            {item.win_amount}
          </p>
        </div>
      </div>
      <hr />
      {showaccordian === item && (
        <div className="details">
          <h4 className="mt-3 text-start">Details</h4>
          <ul className="p-0">
            {/* Render details */}
            <li className="bg-slate-200 p-1 rounded-md mb-2">
              <div className="d-flex justify-content-between gap-2">
                <p className="mb-0 text-dark text-sm-custum whitespace-nowrap">
                  Order number
                </p>
                <p className="d-flex align-items-center gap-1 mb-0 text-sm-custum text-muted">
                  {item.orderid} <FaRegCopy />
                </p>
              </div>
            </li>
            {/* Additional list items go here */}
          </ul>
        </div>
      )}
    </React.Fragment>
  ))
)}
</Card.Body>
                  </Card>

                  {/* Pagination controls for My History */}
                  <div className="pagination-controls bg-white mt-4">
                    <nav>
                      <ul className="pagination">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                          <button onClick={() => paginate(currentPage - 1)} className="page-link" disabled={currentPage === 1}>
                            <RiArrowLeftSLine />
                          </button>
                        </li>
                        <li className="page-item">
                          <span className="page-info">
                            {currentPage} of {Math.ceil(userListData.length / itemsPerPage)}
                          </span>
                        </li>
                        <li className={`page-item ${currentPage === Math.ceil(userListData.length / itemsPerPage) ? 'disabled' : ''}`}>
                          <button onClick={() => paginate(currentPage + 1)} className="page-link" disabled={currentPage === Math.ceil(userListData.length / itemsPerPage)}>
                            <RiArrowRightSLine />
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </Container>
    </section>
  );
}




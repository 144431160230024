import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, Container, Modal, Form, Button } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { IoIosClock } from "react-icons/io";
import { IoDocumentTextSharp } from "react-icons/io5";
import One from "../../assets/images/one.png";
import Two from "../../assets/images/two.png";
import Three from "../../assets/images/three.png";
import Four from "../../assets/images/four.png";
import Five from "../../assets/images/five.png";
import Six from "../../assets/images/six.png";
import Seven from "../../assets/images/seven.png";
import Eight from "../../assets/images/eight.png";
import Nine from "../../assets/images/nine.png";
import Zero from "../../assets/images/zero.png";
import beepSound from "../../assets/Audio/beep.mp3";
import axios from "axios";
import Swal from "sweetalert2";
import { useCredit } from './CreditContext';
import { ResultContext } from './ResultContext';
// import Winpopup from '../Winpoup';
import { useAppContext } from './AppContext';
import { FaIndianRupeeSign } from "react-icons/fa6";
import WingoChart from "./WingoChart";

export default function Wingo(data) {
  const [activeButtons, setActiveButtons] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const user_id = localStorage.getItem("user_id");
  const [userBetData, setUserBetData] = useState(null);
  const { updateCredit } = useCredit();
  const [isAgreed, setIsAgreed] = useState(true);
  const [isBetPlacedSuccessfully, setIsBetPlacedSuccessfully] = useState(true);
  const isLoading = useRef(false);
  const [loading, setLoading] = useState(false);
  const [remainingSec, setRemainingSec] = useState(0);
  const [periodId, setPeriodId] = useState("");
  const [scopeValues, setScopeValues] = useState([]);
  const [betMultipleValues, setBetMultipleValues] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState(null);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedNumber, setSelectedNumber] = useState("");
  const startTimeRef = useRef(Math.floor(new Date(data.startTime).getTime() / 1000));
  const endTimeRef = useRef(Math.floor(new Date(data.endTime).getTime() / 1000));
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const navigate = useNavigate();

  const category_slug = localStorage.getItem("category_slug");
  const subcategory_slug = localStorage.getItem("sub_category_slug");
  const cat_slug = localStorage.getItem("category_slug");
  const sub_cat_slug = localStorage.getItem("sub_category_slug");
  const color = localStorage.getItem("color");
  const name = localStorage.getItem("bigsmallname");
  const category_name = localStorage.getItem("category_name");
  const subcategory_name = localStorage.getItem("sub_category_name");
  const supersubcategory_name = localStorage.getItem("sub_category_name");
  const supersubcategory_slug = localStorage.getItem("sub_category_slug");

  const intervalM = localStorage.getItem("selectedInterval");
  const game_type_time = localStorage.getItem("selectedInterval");

  const number = localStorage.getItem("number");
  const big_small = localStorage.getItem("Bigsmall");
  const amount = localStorage.getItem("totalAmount");
  const point = localStorage.getItem("points");
  // const period_id = localStorage.getItem("issueNumber");
  const [selectedTypeID, setSelectedTypeID] = useState(null);
  const [popupRemainingSec, setPopupRemainingSec] = useState(3);

  const [balance, setBalance] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [activeBalanceButton, setActiveBalanceButton] = useState([]);
  const [activeMultiplierButton, setActiveMultiplierButton] = useState(1)
  const [showFiveSecondsLeft, setShowFiveSecondsLeft] = useState(false);
  const [users, setUsers] = useState([]);
  const [userListData, setUserListData] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  // alert(currentPage)
  const itemsPerPage = 7;

  useEffect(() => {
    if (!isLoading.current) {
      if (intervalM) {
        fetchData(intervalM);
      }
      fetchSuperCat();
      fetchcolorlistAndCompareResults(intervalM);
    }
  }, [intervalM, category_slug, subcategory_slug]);

  /****************************************************************************/

  const fetchData = async (intervalM) => {
    try {
      isLoading.current = true;
      const url = `${process.env.REACT_APP_API_URL}color-Game-Issue`;

      setLoading(true);
      const config = {
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/json',
        },
        data: { intervalM },
      };

      const response = await axios(config);
      setUsers(response.data.data);
      const { issueNumber, startTime, endTime, remainingTime } = response.data.data;
      setPeriodId(issueNumber);
      setRemainingSec(remainingTime);

      // Update local storage with the start time
      const startTimestamp = Math.floor(new Date(startTime).getTime() / 1000);
      const endTimestamp = Math.floor(new Date(endTime).getTime() / 1000);
      localStorage.setItem('startTime', startTimestamp);
      // alert(startTimestamp);
      updateRemainingTime(startTimestamp, endTimestamp, remainingTime);
      isLoading.current = false;
      setLoading(false);
    } catch (error) {
      isLoading.current = false;
      console.error('Error fetching user data:', error);
      setLoading(false);
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    const formattedMins = mins.toString().padStart(2, '0');
    const formattedSecs = secs.toString().padStart(2, '0');
    return `${formattedMins}:${formattedSecs}`;
  };

  const updateRemainingTime = (startTime, endTime, remainingTime) => {
    setRemainingSec(remainingTime);

    // Trigger popup if 5 seconds are remaining
    if (remainingTime === 5 && remainingTime > 0) {
      setShowFiveSecondsLeft(true);
      setPopupRemainingSec(5);
      setShowPopup(true);
      setIsopen(false);
    }

    if (remainingTime === 0) {
      setTimeout(() => {
        setShowPopup(false);
        setShowFiveSecondsLeft(false);
        // console.log('Remaining Time:', remainingTime);
        // console.log('Is Bet Placed Successfully:', isBetPlacedSuccessfully);

        // if (isBetPlacedSuccessfully) {
        //   console.log("Bet placed successfully. Fetching color list and results...");

        //    fetchcolorlistAndCompareResults();
        // } else {
        //   console.log("Bet not placed successfully. Not calling fetchcolorlistAndCompareResults.");
        // }
        fetchcolorlistAndCompareResults(intervalM);
        if (!isLoading.current) {
          fetchResults(intervalM);
        }
        fetchData(selectedInterval);
      }, 1000);
    }
  };

  // Timer to update remaining time every second
  useEffect(() => {
    const timer = setInterval(() => {
      const storedStartTime = parseInt(localStorage.getItem('startTime'), 10);
      const intervalSec = selectedInterval * 60;

      if (storedStartTime) {
        const endTime = storedStartTime + intervalSec;
        const remainingTime = Math.max(0, endTime - Math.floor(Date.now() / 1000));

        updateRemainingTime(storedStartTime, endTime, remainingTime);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [selectedInterval]);

  // Popup countdown
  useEffect(() => {
    if (showPopup) {
      const popupTimer = setInterval(() => {
        setPopupRemainingSec((prevPopupSec) => {
          if (prevPopupSec > 1) {
            return prevPopupSec - 1;
          } else {
            clearInterval(popupTimer);
            setShowPopup(false);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(popupTimer);
    }
  }, [showPopup]);


  useEffect(() => {
    if (userBetData && userBetData.length > 0) {
      const intervalM = userBetData[0].intervalM;
      const typeID = userBetData[0].typeID;
      setSelectedInterval(intervalM);
      localStorage.setItem("selectedInterval", intervalM);

      setSelectedTypeID(typeID);
      startTimeRef.current = Math.floor(Date.now() / 1000);
    }
  }, [userBetData]);


  useEffect(() => {
    if (selectedTypeID) {
      fetchRuleData(selectedTypeID);
      // fetchcolorlistAndCompareResults(selectedTypeID);
    }
  }, [selectedTypeID]);

  const handleTabClick = (intervalM, typeID) => {
    setSelectedInterval(intervalM);
    setSelectedTypeID(typeID);
    fetchData(intervalM);
    // if (!isLoading.current) {
      fetchResults(intervalM);
    // }
    if (!startTimeRef.current) {
      startTimeRef.current = Math.floor(Date.now() / 1000);
      localStorage.setItem('startTime', startTimeRef.current);
    }
  };



  // -----------------------game super sub category ---------------------------------------------

  const fetchSuperCat = async () => {
    try {
      isLoading.current = true;
      const url = `${process.env.REACT_APP_API_URL}game-super-sub-category`;

      setLoading(true);
      const config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: { user_id, cat_slug: cat_slug, sub_cat_slug: sub_cat_slug },
      };

      const response = await axios(config);
      const responseData1 = response.data.data;
      setUserBetData(responseData1);
      if (responseData1.length > 0) {
        const firstItem = responseData1[0];
        const scopeString = firstItem.scope;
        const betMultipleString = firstItem.betMultiple;
        const typeName = firstItem.typeName;
        const slug = firstItem.slug;
        const intervalM = firstItem.intervalM;
        const typeIds = firstItem.typeID;

        const scopes = scopeString.split("|").map(Number);
        const betMultiples = betMultipleString.split("|").map(Number);
        localStorage.setItem("super_sub_cat_name", typeName);
        localStorage.setItem("super_sub_cat_slug", slug);
        localStorage.setItem("intervalM", intervalM);
        localStorage.setItem("typeID", typeIds);
        setScopeValues(scopes);
        setBetMultipleValues(betMultiples);
      }

      isLoading.current = false;
      setLoading(false);
    } catch (error) {
      isLoading.current = false;
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (!isLoading.current) {
  //   fetchSuperCat();
  //   }
  // }, [cat_slug, sub_cat_slug]);

  //  This is Bet Place API
  const [lastButtonClicked, setLastButtonClicked] = useState(null);

  const handleSaveTotalAmount = async () => {
    if (!isAgreed) {
      Swal.fire({
        title: "Error!",
        text: "Please agree to the pre-sales rule.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    if (totalAmount <= 0) {
      Swal.fire({
        title: "Error!",
        text: "Total amount must be greater than zero.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    // setAmount(totalAmount);
    localStorage.setItem("amount", totalAmount);

    try {
      isLoading.current = true;
      setLoading(true);

      // Wait for 2 seconds
      // await new Promise((resolve) => setTimeout(resolve, 2000));

      const url = `${process.env.REACT_APP_API_URL}color-bet-place`;

      const data = {
        user_id,
        category_slug,
        category_name,
        subcategory_slug,
        subcategory_name,
        supersubcategory_name,
        supersubcategory_slug,
        game_type_time: selectedInterval,
        period_id: periodId ? periodId.toString() : "",
        quantity: quantity ? quantity.toString() : "",
        amount: totalAmount.toString(),
        point: totalAmount ? totalAmount.toString() : "",
      };
      // Check if any button is selected
      if (lastButtonClicked === "color" && color) {
        data.color = color.toString();
      } else if (lastButtonClicked === "number" && number) {
        if (numberColor == 'linear-gradient(to right, violet, #fd565c)') {
          // data.color = 'red violet';
          data.number = number.toString();
        } else if (numberColor == 'linear-gradient(to right, #40ad72, violet)') {
          data.color = 'green violet';
          data.number = number.toString();
        } else {
          data.number = number.toString();
          data.color = getColorName("number", number);
        }


      } else if (lastButtonClicked === "big_small" && big_small) {
        data.big_small = big_small.toString();
        data.color = getColorName("big_small", big_small);
      } else {
        Swal.fire({
          title: "Error!",
          text: "Please select color, number, or big/small.",
          icon: "error",
          confirmButtonText: "OK",
        });
        setLoading(false);
        return;
      }

      const config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);

      if (response.data.success === "1") {
        Swal.fire({
          title: "Success!",
          text: response.data.message,
          icon: "success",
          confirmButtonText: "OK",
        });
        setIsBetPlacedSuccessfully(true);
        setIsopen(false);
        await fetchUpdatedCredit();
        await fetchcolorlistAndCompareResults(intervalM);
        // window.location.href = "/WingoChart";
        // setTimeout(() => {
        //   fetchResults();
        // }, 3000);
      } else if (response.data.success === "0") {
        Swal.fire({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "Something went wrong. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        setIsBetPlacedSuccessfully(false);
      }
      isLoading.current = false;
      setLoading(false);
    } catch (error) {
      isLoading.current = false;
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
    setIsBetPlacedSuccessfully(false);
  };

  // ------------------------------RESULT API-------------------------------------------------
  const [results, setResults] = useState([]);
  const [resultsData, setResultsData] = useState([]);

  const fetchResults = async (typeID) => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}one-min-result`;

      const config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: { gametype: typeID, page: currentPage, perpage: itemsPerPage },
      };

      const response = await axios(config);
      const responseData = response.data.data;

      setResults(responseData);
      setTotalPages(response.data.totalPages);
      isLoading.current = false;
      setLoading(false);
    } catch (error) {
      console.error("Error fetching results:", error);
      isLoading.current = false;
      setLoading(false);
    }
  };


  // --------------------------------Result & color-bet-list--------------------------------------


  const fetchcolorlistAndCompareResults = async (intervalM) => {
    try {
      // Fetch color-bet-list first
      const colorListUrl = `${process.env.REACT_APP_API_URL}color-bet-list`;

      const colorListConfig = {
        method: "POST",
        url: colorListUrl,
        headers: {
          "Content-Type": "application/json",
        },
        data: { user_id, category_slug: category_slug, subcategory_slug: subcategory_slug },
      };

      const colorListResponse = await axios(colorListConfig);
      const colorListData = colorListResponse.data.data;

      if (colorListData && colorListData.length > 0) {
        const colorPeriodId = colorListData[0].period_id;
        const resultDataShow = colorListData[0].result;
        // alert(resultDataShow);

        // Fetch one-min-result and compare
        const resultUrl = `${process.env.REACT_APP_API_URL}one-min-result`;

        const resultConfig = {
          method: "POST",
          url: resultUrl,
          headers: {
            "Content-Type": "application/json",
          },
          data: { gametype: intervalM, page: currentPage, perpage: itemsPerPage },
          
        };
        const resultResponse = await axios(resultConfig);
        const resultData = resultResponse.data.data;

        if (resultData && resultData.length > 0) {
          const resultPeriodId = resultData[0].periodid;

          if (colorPeriodId === resultPeriodId) {
            setResultsData([resultData[0]]);
            

            if (resultDataShow === "win") {
              setWinpopup(true);
              setLosspopup(false);
            } else {
              setLosspopup(true);
              setWinpopup(false);
            }
          } else {
            setWinpopup(false);
            setLosspopup(false);
          }
        }
      }

      setLoading(false);
      isLoading.current = false;
    } catch (error) {
      console.error("Error fetching and comparing results:", error);
      setLoading(false);
      isLoading.current = false;
    }
  };

  //----------- this is credit amout API for refreshing the credit amount-----------------
  const fetchUpdatedCredit = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}profile-list`;

      const config = {
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/json',
        },
        data: { user_id },
      };

      const response = await axios(config);

      if (response.data.data) {
        const newCredit = response.data.data.credit;
        updateCredit(newCredit);
      }
    } catch (error) {
      console.error('Error fetching updated credit:', error);
    }
  };

  const [ruleData, setRuleData] = useState(null);

  const fetchRuleData = async (typeID) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}color-Game-RuleByTypeId`;
      const config = {
        method: 'POST', // Use POST method to send body
        url: url,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          typeID: typeID,
        }),
      };
      const response = await axios(config);
      setRuleData(response.data.data[0]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching rule data:', error.response ? error.response.data : error.message);
      setLoading(false);
    }
  };



  const toggleClass = (buttonId) => {
    setActiveButtons(buttonId === activeButtons ? null : buttonId);
  };

  const [showmodel, setShowmodel] = useState(false);

  const handlemodelclose = () => setShowmodel(false);
  const handleShowmodel = () => setShowmodel(true);

  const [activebtn, setActivebtn] = useState(null);

  const toggleClassamt = (buttonId, value) => {
    setActivebtn(buttonId === activebtn ? null : buttonId);
    // setBalance(value);
    setActiveBalanceButton(value);
  };

  const [show, setShow] = useState(false);
  const [placement, setPlacement] = useState("");
  const [numberColor, setNumberColor] = useState();
  const [bsmall, setBsmall] = useState("");

  const handleMultiplierButtonClick = (mult) => {
    setQuantity(mult);
    setActiveMultiplierButton(mult);
  };

  const handleBalanceButtonClick = (value) => {
    setBalance(value);
    setActiveBalanceButton(value || value * value);
    setActiveMultiplierButton(null);

    localStorage.setItem("point", value);
    localStorage.setItem("Quantity", 1);
  };

  const increment = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
    localStorage.setItem("Quantity", quantity + 1);
  };

  const decrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
      localStorage.setItem("Quantity", quantity - 1);
    }
  };

  const totalAmount = balance * quantity;

  const handleClose = () => setShow(false);
  const handleShow = (placement) => {
    setShow(true);
    setPlacement(placement);
    setBsmall(bsmall);
  };

  const bigsmall = [
    { name: "Big", color: "green" }, // #40ad72
    { name: "Small", color: "red" }, // #fd565c
  ];
  const [isopen, setIsopen] = useState(false);

  const resetValues = () => {
    setActiveBalanceButton(null);
    setQuantity(1);
    setActiveMultiplierButton(null);
  }

  const Togglepopup = () => {
    setIsopen(!isopen);
    if (!isopen) {
      resetValues();
    }
  };
  const Toggleoff = () => {
    setIsopen(!isopen);
  };


  const selectColors = [
    { name: "Green", color: "green" },
    { name: "Violet", color: "violet" },
    { name: "Red", color: "red" },
  ];

  const balls = [
    { number: "0", colorName: "red violet", color: "linear-gradient(to right, violet, #fd565c)", image: Zero },
    { number: "1", color: "green", image: One },
    { number: "2", color: "red", image: Two },
    { number: "3", color: "green", image: Three },
    { number: "4", color: "red", image: Four },
    { number: "5", colorName: "green violet", color: "linear-gradient(to right, #40ad72, violet)", image: Five },
    { number: "6", color: "red", image: Six },
    { number: "7", color: "green", image: Seven },
    { number: "8", color: "red", image: Eight },
    { number: "9", color: "green", image: Nine },
  ];

  const getColorName = (selectedType, value) => {
    if (selectedType === "color") {
      const selectedColor = selectColors.find(color => color.name.toLowerCase() === value.toLowerCase());
      return selectedColor ? selectedColor.color : "unknown color";
    } else if (selectedType === "number") {
      const ball = balls.find(ball => ball.number === value);
      return ball ? ball.color : "unknown color";
    } else if (selectedType === "big_small") {
      const bs = bigsmall.find(item => item.name.toLowerCase() === value.toLowerCase());
      return bs ? bs.color : "unknown color";
    }
    return "unknown color";
  };


  const handleAgreeChange = (event) => {
    setIsAgreed(event.target.checked);
  };

  const handleButtonClick = (color) => {
    // Change the header color when button is clicked
    setHeaderColor(color);
  };
  const [headerColor, setHeaderColor] = useState("");

  const toggleClassamtcolor = (buttonId, color, ballNumber) => {
    // Add your logic here for toggling class based on buttonId
    // Call handleButtonClick with color parameter
    handleButtonClick(color);
  };

  const setColorInLocalStorage = (color) => {
    localStorage.setItem("color", color);
  };

  const setBallNumberInLocalStorage = (number) => {
    localStorage.setItem("number", number);
  };

  const handleColorButtonClick = (color) => {
    setColorInLocalStorage(color);
    Togglepopup();
    setSelectedName(color);
    handleButtonClick();
    toggleClassamtcolor(1, color);
    setLastButtonClicked("color");
  };

  const handleBallClick = (color, number) => {
    setBallNumberInLocalStorage(number);
    Togglepopup();
    setSelectedName(number);
    handleButtonClick();
    toggleClassamtcolor(1, color);
    setNumberColor(color)
    setLastButtonClicked("number");
  };

  const setBigsmallAndColorInLocalStorage = (name) => {
    localStorage.setItem("Bigsmall", name);
  };

  const handleBigsmallButtonClick = (name, color) => {
    setBigsmallAndColorInLocalStorage(name);
    setSelectedName(name);
    Togglepopup();
    handleButtonClick(name);
    toggleClassamtcolor(1, color);
    setLastButtonClicked("big_small");
  };


  const removeHtmlTags = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  };
  const [losspopup, setLosspopup] = useState(false);
  const lossclose = () => setLosspopup(false);
  const LosscloseShow = () => setLosspopup(true);

  const [winpopup, setWinpopup] = useState(false);

  return (
    <div>
      <section className="wingosection">
        <div className="wingo padding_all">
          <div className="allbefore position-relative">
            <Container>
              <React.Fragment>
                <div
                  className={`popup offcanvas_custum ${isopen ? "active" : ""}`}
                >
                  <div onClick={Toggleoff} className="overlay_canvass">
                    {" "}
                  </div>
                  <div className="popupbottomtotop">
                    <div
                      className="bg_color_header"
                      style={{ background: headerColor }}
                    >
                      <div className="titlegame">
                        <h3 className="text-white">Win Go 1Min</h3>
                        <h4>
                          Select
                          <span> {selectedColor}</span>
                          <span> {selectedName}</span>
                          <span> {selectedNumber}</span>
                        </h4>
                      </div>
                    </div>
                    <div className="p-3 pb-0 d-flex flex-col gap-2">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <p className="mb-0 text-base">Balance</p>
                        <div className="d-flex justify-content-center align-items-center">
                          {[1, 10, 100, 1000].map((value) => (
                            <button
                              key={value}
                              className={`button ${activeBalanceButton === value ? "active_btn" : ""
                                } btn_amt`}
                              onClick={() => handleBalanceButtonClick(value)}
                            >
                              {value}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center w-100 mt-1">
                        <p className="mb-0 text-base">Quantity</p>
                        <div className="d-flex justify-content-center align-items-center">
                          <button className="counterbtnsd" onClick={decrement}>
                            -
                          </button>
                          <input
                            className="inputcount"
                            value={quantity}
                            readOnly
                          />
                          <button className="counterbtnsd" onClick={increment}>
                            +
                          </button>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end align-items-center mt-1">
                        {[1, 5, 10, 20, 50, 100].map((mult) => (
                          <button
                            key={mult}
                            className={`button ${activeMultiplierButton === mult ? "active_btn" : ""
                              } btn_amt`}
                            onClick={() => handleMultiplierButtonClick(mult)}
                          >
                            X{mult}
                          </button>
                        ))}
                      </div>
                      <div className="d-flex align-items-center justify-content-evenly mt-1"></div>
                      <div className="d-flex align-items-center py-2">
                        <div className="d-flex align-items-center">
                          <input
                            id="agreecheckbox"
                            type="checkbox"
                            name="r"
                            value="3"
                            className="mr-1"
                            onChange={handleAgreeChange}
                            checked={isAgreed}
                          />
                          <label htmlFor="03" className="text-danger text-sm">
                            I Agree
                          </label>
                        </div>
                        <button onClick={handleShowmodel}>
                          <h6 className="text-sm ml-2 text-danger mb-0">
                            {"<<"}Pre Rule{">>"}
                          </h6>
                        </button>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="closebtn text-base" onClick={Togglepopup}>
                        cancel
                      </div>
                      <div
                        className="totalamount text-base"
                        onClick={() => handleSaveTotalAmount(totalAmount)}
                        style={{ background: headerColor }}
                      >
                        Total Amount : {totalAmount}.00
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
              <div className="windgo_tabs">
                <Tabs>
                  <TabList className="d-flex justify-content-between pl-0 bg-white rounded p-1">
                    {userBetData &&
                      userBetData
                        .sort((a, b) => parseInt(a.intervalM) - parseInt(b.intervalM))
                        .map((bet, index) => (
                          <Tab key={index} onClick={() => handleTabClick(bet.intervalM, bet.typeID)}>
                            <div className="tabs_windgo">
                              <div className="d-flex justify-content-center align-items-center icon_rounded">
                                <IoIosClock />
                              </div>
                              <p>
                                Win Go <span>{bet.intervalM}Min</span>
                              </p>
                            </div>
                          </Tab>
                        ))}
                  </TabList>
                  <TabPanel>
                    <div className="bg-color-all tab_active p-2 rounded border_offset">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="Howtoplay">
                          {selectedTypeID !== null && (
                            <h5 onClick={handleShow} className="mb-0 d-flex align-items-center justify-content-center gap-1">
                              <IoDocumentTextSharp />
                              How To Play
                            </h5>
                          )}
                          <Modal show={show} onHide={handleClose}>
                            <Modal.Header className="bg-color-all" closeButton>
                              <Modal.Title className="position-absolute text-light top-1.5	left-26 text-center text-base">How to play</Modal.Title>
                            </Modal.Header>

                            <Modal.Body className="modal-body-scroll">
                              {loading ? (
                                <p>Loading...</p>
                              ) : (
                                ruleData && (
                                  <div>
                                    {removeHtmlTags(ruleData.gamePresentation).split('\n').map((line, index) => (
                                      <p key={index}>{line}</p>
                                    ))}
                                    {/* Display other data fields here */}
                                  </div>
                                )
                              )}
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                          {selectedInterval !== null && (
                            <p>Win Go {selectedInterval} Min</p>
                          )}
                          <div className="d-flex justify-content-between gap-1 mt-1">
                            <div className="w-6 h-6">
                              <img src={One} alt="One" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Two} alt="Two" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Three} alt="Three" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Four} alt="Four" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Five} alt="Five" />
                            </div>
                          </div>
                        </div>

                        {/* <h1 className="countdown">Remaining Time: {formatTime(popupRemainingSec)}</h1> */}
                        {showPopup && (
                          <div className="popup d-flex justify-content-between countdown_time">
                            {/* Render your popup content here */}

                          </div>
                        )}

                        <div className="Countdown_timer">
                          <h5 className="text-white text-sm">Time Remaining</h5>
                          <div className="d-flex justify-content-between countdown_time">
                            <div className="countdown">
                              {formatTime(remainingSec)}
                            </div>
                          </div>



                          <h5 className="text-white text-sm mt-1">{periodId}</h5>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="bg-color-all tab_active p-2 rounded border_offset">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="Howtoplay">
                          {selectedTypeID !== null && (
                            <h5 onClick={handleShow} className="mb-0 d-flex align-items-center justify-content-center gap-1">
                              <IoDocumentTextSharp />
                              How To Play
                            </h5>
                          )}
                          <Modal show={show} onHide={handleClose}>
                            <Modal.Header className="bg-color-all" closeButton>
                              <Modal.Title className="text-white d-flex justify-center">How to play</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="modal-body-scroll">
                              {loading ? (
                                <p>Loading...</p>
                              ) : (
                                ruleData && (
                                  <div>
                                    {removeHtmlTags(ruleData.gamePresentation).split('\n').map((line, index) => (
                                      <p key={index}>{line}</p>
                                    ))}
                                    {/* Display other data fields here */}
                                  </div>
                                )
                              )}
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                          {selectedInterval !== null && (
                            <p>Win Go {selectedInterval} Min</p>
                          )}
                          <div className="d-flex justify-content-between gap-1 mt-1">
                            <div className="w-6 h-6">
                              <img src={One} alt="One" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Two} alt="Two" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Three} alt="Three" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Four} alt="Four" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Five} alt="Five" />
                            </div>
                          </div>
                        </div>
                        <div className="Countdown_timer">
                          <h5 className="text-white text-sm">Time Remaining</h5>
                          <div className="d-flex justify-content-between countdown_time">
                            <div className="countdown">
                              {formatTime(remainingSec)}
                            </div>
                          </div>
                          <h5 className="text-white text-sm mt-1">{periodId}</h5>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="bg-color-all tab_active p-2 rounded border_offset">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="Howtoplay">

                          {selectedTypeID !== null && (
                            <h5 onClick={handleShow} className="mb-0 d-flex align-items-center justify-content-center gap-1">
                              <IoDocumentTextSharp />
                              How To Play
                            </h5>
                          )}
                          <Modal show={show} onHide={handleClose}>
                            <Modal.Header className="bg-color-all" closeButton>
                              <Modal.Title className="text-white d-flex justify-center">How to play</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="modal-body-scroll">
                              {loading ? (
                                <p>Loading...</p>
                              ) : (
                                ruleData && (
                                  <div>
                                    {removeHtmlTags(ruleData.gamePresentation).split('\n').map((line, index) => (
                                      <p key={index}>{line}</p>
                                    ))}
                                    {/* Display other data fields here */}
                                  </div>
                                )
                              )}
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                          {selectedInterval !== null && (
                            <p>Win Go {selectedInterval} Min</p>
                          )}
                          <div className="d-flex justify-content-between gap-1 mt-1">
                            <div className="w-6 h-6">
                              <img src={One} alt="One" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Two} alt="Two" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Three} alt="Three" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Four} alt="Four" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Five} alt="Five" />
                            </div>
                          </div>
                        </div>
                        <div className="Countdown_timer">
                          <h5 className="text-white text-sm">Time Remaining</h5>
                          <div className="d-flex justify-content-between countdown_time">
                            <div className="countdown">
                              {formatTime(remainingSec)}
                            </div>
                          </div>
                          <h5 className="text-white text-sm mt-1">{periodId}</h5>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="bg-color-all tab_active p-2 rounded border_offset">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="Howtoplay">
                          {selectedTypeID !== null && (
                            <h5 onClick={handleShow} className="mb-0 d-flex align-items-center justify-content-center gap-1">
                              <IoDocumentTextSharp />
                              How To Play
                            </h5>
                          )}
                          <Modal show={show} onHide={handleClose}>
                            <Modal.Header className="bg-color-all" closeButton>
                              <Modal.Title className="text-white d-flex justify-center">How to play</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="modal-body-scroll">
                              {loading ? (
                                <p>Loading...</p>
                              ) : (
                                ruleData && (
                                  <div>
                                    {removeHtmlTags(ruleData.gamePresentation).split('\n').map((line, index) => (
                                      <p className="text-sm" key={index}>{line}</p>
                                    ))}
                                    {/* Display other data fields here */}
                                  </div>
                                )
                              )}
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                          {selectedInterval !== null && (
                            <p>Win Go {selectedInterval} Min</p>
                          )}
                          <div className="d-flex justify-content-between gap-1 mt-1">
                            <div className="w-6 h-6">
                              <img src={One} alt="One" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Two} alt="Two" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Three} alt="Three" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Four} alt="Four" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Five} alt="Five" />
                            </div>
                          </div>
                        </div>
                        <div className="Countdown_timer">
                          <h5 className="text-white text-sm">Time Remaining</h5>
                          <div className="d-flex justify-content-between countdown_time">
                            <div className="countdown">
                              {formatTime(remainingSec)}
                            </div>
                          </div>
                          <h5 className="text-white text-sm mt-1">{periodId}</h5>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="bg-color-all tab_active p-2 rounded border_offset">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="Howtoplay">
                          {selectedTypeID !== null && (
                            <h5 onClick={handleShow} className="mb-0 d-flex align-items-center justify-content-center gap-1">
                              <IoDocumentTextSharp />
                              How To Play
                            </h5>
                          )}
                          {selectedInterval !== null && (
                            <p>Win Go {selectedInterval} Min</p>
                          )}
                          <div className="d-flex justify-content-between gap-1 mt-1">
                            <div className="w-6 h-6">
                              <img src={One} alt="One" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Two} alt="Two" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Three} alt="Three" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Four} alt="Four" />
                            </div>
                            <div className="w-6 h-6">
                              <img src={Five} alt="Five" />
                            </div>
                          </div>
                        </div>
                        <div className="Countdown_timer">
                          <h5 className="text-white text-sm">Time Remaining</h5>
                          <div className="d-flex justify-content-between countdown_time">
                            <div className="countdown">
                              {formatTime(remainingSec)}
                            </div>
                          </div>
                          <h5 className="text-white text-sm mt-1">{periodId}</h5>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>

              <div className="game_board">
                <Card>
                  {showFiveSecondsLeft && (
                    <div className="remainingtime">
                      <h3>{formatTime(popupRemainingSec)}</h3>
                    </div>
                  )}
                  <Card.Body className="p-1">
                    <div className="d-flex align-items-center justify-content-between">
                      {selectColors.map((item, index) => (
                        <button
                          key={index}
                          className="button_color_game"
                          onClick={() => handleColorButtonClick(item.name, item.color)}
                        >
                          {item.name}
                        </button>
                      ))}
                    </div>
                    <div className="bg-gray-200 rounded p-2 mt-1">
                      <div className="d-flex justify-content-between flex-wrap gap-2 mt-1">
                        {balls.map((ball) => (
                          <div key={ball.number} className="w-12 h-12">
                            <img
                              onClick={() =>
                                handleBallClick(ball.color, ball.number)
                              }
                              src={ball.image}
                              alt={`Ball ${ball.number}`}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-1">
                      <button className="border text-danger multiple_winredom">
                        Random
                      </button>
                      {betMultipleValues.length > 0 &&
                        betMultipleValues.map((bet, index) => (
                          <button
                            key={index}
                            className={`button ${activeButtons === bet ? "active_btn" : ""
                              } multiple_win`}
                            onClick={() => toggleClassamt(bet)}
                          >
                            X{bet}
                          </button>
                        ))}

                    </div>
                    <div className="d-flex align-items-center justify-content-evenly mt-1">
                      {bigsmall.map((item, index) => (
                        <button
                          key={index}
                          variant="primary"
                          onClick={() =>
                            handleBigsmallButtonClick(item.name, item.color)
                          }
                          className="button_color_big_small"
                          style={{ backgroundColor: item.color }}
                        >
                          {item.name}
                        </button>
                      ))}
                    </div>
                    {/* </div> */}
                  </Card.Body>
                </Card>
              </div>
            </Container>
          </div>

          <Modal scrollable={true} show={showmodel} onHide={handlemodelclose}>
            <Modal.Header closeButton className="bg-image-header-rule">
              <Modal.Title className="position-absolute text-dark top-1.5	 left-24 text-center text-base">
                《Pre-sale rules》
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="pb-0 mb-0 text-sm leading-2 ">
                "In order to protect the legitimate rights and interests of users
                participating in the pre-sale and maintain the normal operating
                order of the pre-sale, these rules are formulated in accordance
                with relevant agreements and laws and regulations. country Chapter
                1 Definition1.1 Pre-sale definition: refers to a sales model in
                which a seller offers a bundle of a product or service, collects
                consumer orders through product tools before selling, and makes it
                available to customers. consumers of goods and/or services by
                prior agreement1.2 Presale mode is "deposit" mode. "Consignment"
                refers to the pre-delivery of a fixed number of items prior to
                sale. "Deposit" Scam Join mini games for a chance to win more
                deposits. Deposits can be exchanged directly for goods. Deposit is
                not refundable.1.3 Pre-sale product: A product that is shipped by
                the seller using the pre-sale product tool. Only highlight the
                word presale on the product name or product detail page, and
                products that do not use the presale product tool are not presale.
                1.4 Pre-sale system: refers to the system product tool that helps
                sellers to sell samples before selling.1.5 Product price before
                selling: is the selling price of the product before selling. The
                price of pre-sale items consists of two parts: deposit and final
                payment. "
              </p>
            </Modal.Body>
            <Modal.Footer className="justify-content-center inkow_footer">
              <button
                className="iknow w-60 rounded-xl	leading-8	 text-center text-base text-white"
                onClick={handlemodelclose}
              >
                I know
              </button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* <Button variant="secondary" onClick={wincloseShow}>
       Result
      </Button>

      
      {winpopup && (
        <div className="model_custum">
          <Winpopup />
          <span onClick={winclose} className="close_times">&times;</span>
        </div>
      )} */}

        <div>

          {/* Conditionally render the popup only if results are fetched */}
          {winpopup && (
            <div className="model_custum">
              <div className="winimage">
                <div className="content_win">
                  <h3 className="congrets">Congratulations</h3>
                  <div className="result_name d-flex justify-content-between align-items-center">
                    <p className="mb-0 pb-0">Result Name</p>
                    <p className="mb-0 pb-0">{resultsData && resultsData[0]?.color}</p>
                    <p className="mb-0 pb-0">{resultsData && resultsData[0]?.number}</p>
                    <p className="mb-0 pb-0">Win</p>
                  </div>
                  <h4>Bonus</h4>
                  <h3 className="d-flex justify-content-center">
                    <FaIndianRupeeSign /> 1.00
                  </h3>
                  <h6 className="d-flex justify-content-center winingamountperied">
                    Peried Win: {resultsData && resultsData[0]?.game_type_time} Minutes {resultsData && resultsData[0]?.periodid}
                  </h6>
                </div>
              </div>
              <span onClick={() => setWinpopup(false)} className="close_times">&times;</span>
            </div>
          )}
        </div>

        <div>
          {/* Conditionally render the popup only if results are fetched */}
          {losspopup && (
            <div className="model_custum">
              <div className="lossimage">
                <div className="content_loss">
                  <h3 className="Sorrytext">Sorry</h3>
                  <div className="result_name d-flex justify-content-between align-items-center">
                    <p className="mb-0 pb-0 result-name">Result Name</p>
                    <p className="mb-0 pb-0 result-color">{resultsData && resultsData[0]?.color}</p>
                    <p className="mb-0 pb-0 result-time">{resultsData && resultsData[0]?.number}</p>
                    {/* <p className="mb-0 pb-0 result-type">{results && results[0]?.big_small}</p> */}
                    <p className="mb-0 pb-0 result-type">Small</p>
                  </div>
                  <h3 className="Loss">Lose</h3>
                </div>
                <h6 className="period-css">
                  Period {resultsData && resultsData[0]?.game_type_time} Minutes {resultsData && resultsData[0]?.periodid}
                </h6>
              </div>
              <span onClick={() => setLosspopup(false)} className="close_times">&times;</span>
            </div>
          )}

        </div>

      </section>
      <WingoChart results={results}
        setResults={setResults} />
    </div>
  );
}


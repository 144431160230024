import React, { useEffect, useRef, useState } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { FaInfoCircle } from "react-icons/fa";
import { BsBank2 } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { RiBankCardFill } from "react-icons/ri";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import { IoKeyOutline } from "react-icons/io5";
import $ from 'jquery'; // Import jQuery
import 'select2'; // Import Select2 CSS
import 'select2/dist/css/select2.min.css'; // Import Select2 CSS
import Bankname from './Bankname.json';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const AddBankCard = () => {
    const user_id = localStorage.getItem("user_id");

    const selectRef = useRef(null);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        user_id: user_id, // Assuming this is the user's ID
        account_number: '',
        ifsc_code: '',
        name: '',
        phoneNumber: '',
        email: '',
        bank_name: '',
    });

    useEffect(() => {
        // Initialize Select2 with the change event listener
        $(selectRef.current).select2({
            theme: 'classic',
            placeholder: 'Search Bank...',
            allowClear: true,
        }).on('change', function (e) {
            const selectedBank = $(e.target).val();
            setFormData((prevState) => ({
                ...prevState,
                bank_name: selectedBank,
            }));
        });

        return () => {
            $(selectRef.current).select2('destroy');
        };
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            localStorage.setItem("account_number", formData.account_number);
            localStorage.setItem("ifsc_code", formData.ifsc_code);
            localStorage.setItem("name", formData.name);
            localStorage.setItem("phoneNumber", formData.phoneNumber);
            localStorage.setItem("email", formData.email);
            localStorage.setItem("bank_name", formData.bank_name);
            const response = await axios.post('https://apicolorgame.a2logicgroup.com/api/users/addBankDetails', formData);
            console.log('API Response:', response.data);
            if (response.data.success === "1") {
                toast.success(response.data.message);
                navigate("/Withdraw");
            } else {
                toast.error("Failed to add bank details");
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Error submitting form. Please try again.');
        }
    };

    const isSelected = (id) => {
        // Function to check if bank is selected (if needed)
        // return id === selectedId;
    };

    return (
        <div className="Addbankcard">
            <div className='topheader p-2 bg-color-all d-flex justify-content-between align-items-center'>
                <Link to="/Withdraw" className='text-white text-2xl'><MdOutlineKeyboardArrowLeft /></Link>
                <h4 className="mb-0 text-white">Add Bank Details</h4>
                <Link to="/home" className='w-5'></Link>
            </div>
            <Container>
                <div className="d-flex gap-2 align-items-center bg-white rounded-full px-2 py-1 mt-2">
                    <FaInfoCircle className="mb-0 text-2xl text-danger" />
                    <p className="mb-0 text-sm text-danger text-start leading-4">To ensure the safety of your funds, please bind your bank account</p>
                </div>

                <Form className="mt-2" onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3 select2_box">
                            <Form.Label className="d-flex">
                                <div className="d-flex align-items-center gap-2">
                                    <BsBank2 className='icon_primary' />
                                    <p className='mb-0 text-sm text-dark'>Choose A Bank Name</p>
                                </div>
                            </Form.Label>
                            <select className="form-control text-start" ref={selectRef}>
                                {Bankname && Bankname.map((bank) => (
                                    <option key={bank.id} value={bank.name} selected={isSelected(bank.id)}>
                                        {bank.name}
                                    </option>
                                ))}
                            </select>
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                            <Form.Label className="d-flex">
                                <div className="d-flex align-items-center gap-2">
                                    <FaUser className='icon_primary' />
                                    <p className='mb-0 text-sm text-dark'>Full recipient's name</p>
                                </div>
                            </Form.Label>
                            <Form.Control
                                required
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="outline-none shadow-transparent hover:outline-none focus:shadow-transparent"
                                type="text"
                                placeholder="Full recipient's name"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                            <Form.Label className="d-flex">
                                <div className="d-flex align-items-center gap-2">
                                    <RiBankCardFill className='icon_primary' />
                                    <p className='mb-0 text-sm text-dark'>Bank Account Number</p>
                                </div>
                            </Form.Label>
                            <Form.Control
                                required
                                name="account_number"
                                value={formData.account_number}
                                onChange={handleChange}
                                className="outline-none shadow-transparent hover:outline-none focus:shadow-transparent"
                                type="text"
                                maxlength="14"
                                placeholder="Bank account number"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                            <Form.Label className="d-flex">
                                <div className="d-flex align-items-center gap-2">
                                    <MdOutlinePhoneAndroid className='icon_primary' />
                                    <p className='mb-0 text-sm text-dark'>Phone Number</p>
                                </div>
                            </Form.Label>
                            <Form.Control
                                required
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                className="outline-none shadow-transparent hover:outline-none focus:shadow-transparent"
                                type="tel" maxlength="10"
                                placeholder="Phone Number"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                            <Form.Label className="d-flex">
                                <div className="d-flex align-items-center gap-2">
                                    <IoMdMail className='icon_primary' />
                                    <p className='mb-0 text-sm text-dark'>Mail</p>
                                </div>
                            </Form.Label>
                            <Form.Control
                                required
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="outline-none shadow-transparent hover:outline-none focus:shadow-transparent"
                                type="email"
                                placeholder="Mail"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                            <Form.Label className="d-flex">
                                <div className="d-flex align-items-center gap-2">
                                    <IoKeyOutline className='icon_primary' />
                                    <p className='mb-0 text-sm text-dark'>IFSC Code</p>
                                </div>
                            </Form.Label>
                            <Form.Control
                                required
                                name="ifsc_code"
                                value={formData.ifsc_code}
                                onChange={handleChange}
                                className="outline-none shadow-transparent hover:outline-none focus:shadow-transparent"
                                type="text"
                                placeholder="IFSC Code"
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="d-flex justify-content-center align-items-center">
                                <Button type="submit" className="bg-primary_custum w-100 rounded-full shadow-transparent border-0 outline-none">Save</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Container>
            <ToastContainer />
        </div>
    );
};

export default AddBankCard;

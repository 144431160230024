import React from 'react'
import {Link} from 'react-router-dom';
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import {FaPlus} from 'react-icons/fa'
export default function Bankaccount() {
  
  const account_number = localStorage.getItem("account_number");
  const ifsc_code = localStorage.getItem("ifsc_code");
  const name = localStorage.getItem("name");
  const phoneNumber = localStorage.getItem("phoneNumber");
  const email = localStorage.getItem("email");
  const bank_name = localStorage.getItem("bank_name");

  return (
    <div className="
    ">
       <div className='topheader p-2 bg-color-all d-flex justify-content-between align-items-center'>
        <Link to="/Wingo" className='text-white text-2xl'><MdOutlineKeyboardArrowLeft /></Link>
        <h4 className="mb-0 text-white">Bank Account</h4>
        <Link to="/" className='w-5'></Link>
      </div>
      <div className="container-fluid">
      <div className="card">
        <div className="card-header">
        </div>
            <div className="card-body">
                <div className="d-flex gap-3 justify-content-between align-items-center">
                    <div className="details_page">Bank Name</div>
                    <div className='text-deatails'>{bank_name}</div>
                </div>
                <div className="d-flex gap-3 justify-content-between align-items-center">
                    <div className="details_page">Account Number</div>
                    <div className='text-deatails'>{account_number}</div>
                </div>
                <div className="d-flex gap-3 justify-content-between align-items-center">
                    <div className="details_page">Phone Number</div>
                    <div className='text-deatails'>{phoneNumber}</div>
                </div>
                <div className="form-group d-flex justify-content-start align-items-center gap-2">
                    <input type="checkbox" id='check' />
                    <label htmlFor="check">Select </label>
                </div>
            </div>
      </div>

      <Link to="/AddBankCard" className="no-underline mt-2 d-block"> 
      <div className="card_custum rounded-sm	 ">
          <div className="d-flex justify-content-center p-4  flex-col gap-2">
            <div className="d-flex justify-content-center"><FaPlus className="plusicon"/></div>
              <div className="text-sm"> Add a bank account number</div>
          </div>
      </div>
      </Link>
    </div>
    </div>
  )
}

import React from "react";
import "./Style.css";
import "./Style.scss";
import "./App.css";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Headersec from "./Component/Headersec";
import Header from "./Component/Header";
import Footer from "./Component/Footer";
import Home from "./Pages/Home";
import Wininginfo from "./Pages/Wininginfo";
import Todayearningchart from "./Pages/Todayearningchart";
import Login from "./Pages/Login";
import Register from './Pages/Register';
import Verification from "./Pages/Verification";
import HomeWithoutLogin from "./Pages/HomeWithoutLogin";
import Promotion from "./Pages/Promotion";
import Bankaccount from "./Pages/Bankaccount";
import Dailytasks from "./Pages/Dailytasks";
import Wallet from "./Pages/Wallet";
import Account from "./Pages/Account";
import AddBankCard from "./Pages/AddBankCard";
import Withdrawhistory from "./Pages/Withdrawhistory";
import Deposithistory from "./Pages/Deposithistory";
import Deposit from "./Pages/Deposit";
import Withdraw from "./Pages/Withdraw";
import Activity from "./Pages/Activity";
import Wingo from "./Pages/AllLotteryGames/Wingo";
import Gamek3 from "./Pages/AllLotteryGames/Gamek3";
import Wintrx from "./Pages/AllLotteryGames/Wintrx";
import Game5d from "./Pages/AllLotteryGames/Game5d";
import WingoChart from "./Pages/AllLotteryGames/WingoChart";
import WingoHeader from "./Pages/AllLotteryGames/WingoHeader";
import Details from './Pages/Details';
import CreditContext from "./Pages/AllLotteryGames/CreditContext";
import ForgotPsw from "./Pages/ForgotPsw";
import { ResultContext } from "./Pages/AllLotteryGames/ResultContext";
// import DataContext from "./Pages/AllLotteryGames/DataContext";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/*"
            element={
              <div className="bg-color">
                <Header />
                <Routes>
                  <Route path="/home" element={<Home />} />
                </Routes>
                <Footer />
              </div>
            }
          />
          <Route
            path="/*"
            element={
              <div className="bg-color">
                <Header />
                <Routes>
                  <Route path="/Wininginfo" element={<Wininginfo />} />
                  <Route path="/Todayearningchart" element={<Todayearningchart />} />
                  <Route path="/Dailytasks" element={<Dailytasks />} />
                </Routes>
                <Footer />
              </div>
            }
          />
          <Route path="/Promotion" element={<><Headersec /><Promotion /> <Footer /></>} />
          <Route path="/Wallet" element={<><Wallet /> <Footer /></>} />
          <Route path="/Account" element={<><Account /> <Footer /></>} />
          <Route path="/Activity" element={<><Activity /> <Footer /></>} />
          <Route path="/Wingo" element={<><WingoHeader /><Wingo /></>}/> 
          <Route path="/WingoChart" element={<><WingoChart /></>} />
          <Route path="/ResultContext" element={<><ResultContext /></>} />
          <Route path="/Deposit" element={<><Deposit /></>} />
          <Route path="/Deposithistory" element={<><Deposithistory /></>} />
          <Route path="/Withdraw" element={<><Withdraw /></>} />
          <Route path="/addbankcard" element={<><AddBankCard /></>} />
          <Route path="/Gamek3" element={<><Gamek3 /></>} />
          <Route path="/Game5d" element={<><Game5d /></>} />
          <Route path="/Wintrx" element={<><Wintrx /></>} />
          <Route path="/Withdrawhistory" element={<><Withdrawhistory /></>} />
          <Route path="/Bankaccount" element={<><Bankaccount /></>} />
          <Route path="/" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Details" element={<Details />} />
          <Route path="/CreditContext" element={<CreditContext />} />
          <Route path="/ForgotPsw" element={<ForgotPsw />} />
          {/* <Route path="/DataContext" element={<DataContext />} /> */}

          <Route
            path="/*"
            element={
              <div className="bg-color">
                <Routes>
                  <Route path="/HomeWithoutLogin" element={<HomeWithoutLogin />} />

                  <Route path="/Verification" element={<Verification />} />
                </Routes>
                <Footer />
              </div>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
